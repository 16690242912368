$font-data: (
    'Regular': (normal, normal),
    'Bold': (bold, normal),
    'Italic': (normal, italic),
    'BoldItalic': (bold, italic),
);

@each $font-filename, $font in $fonts {
    $font-name: nth($font, 1);
    $font-valid-data: nth($font, 2);
    @each $font-data-name in $font-valid-data {
        $font-identifier: '#{$font-filename}';
        $font-current-data: map-get($font-data, 'Regular');
        @if $font-data-name != '' {
            $font-identifier: '#{$font-filename}-#{$font-data-name}';
            $font-current-data: map-get($font-data, $font-data-name);
        }

        $font-path: '/assets/fonts/#{$font-filename}/#{$font-identifier}';

        @font-face {
            font-family: $font-name;
            src: url('#{$font-path}.eot');
            src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
                url('#{$font-path}.woff2') format('woff2'),
                url('#{$font-path}.woff') format('woff'),
                url('#{$font-path}.ttf') format('truetype'),
                url('#{$font-path}.svg##{$font-identifier}') format('svg');
            font-weight: nth($font-current-data, 1);
            font-style: nth($font-current-data, 2);
        }
    }
}
