.form-content {
    max-width: 800px;
    padding: 15px 30px;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.form-errors {
    margin-bottom: 15px;
    color: #cc2211;
}

.form-success {
    margin-bottom: 15px;
    color: #11aa11;
}

label {
    text-align: center;
    font-weight: bold;
}

input[type="text"], input[type="password"], textarea, select {
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: block;
    width: 100%;
    margin: 15px 0;
    padding: 10px 20px;
}

button {
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
}

sup.is-required {
    color: #cc2211;
}
