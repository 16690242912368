.authors {
    .author {
        text-decoration: none;
        display: inline-block;
        padding: 10px;

        img {
            display: block;
            border-radius: 50%;
            width: 128px;
        }
    }
}

.author-image {
    display: inline-block;
    border-radius: 5%;
    width: 128px;
}
