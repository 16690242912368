.table {
    width: 100%;

    .table-full {
        width: 100%;
    }

    .table-half {
        width: 50%;
    }

    th, td {
        padding: 5px 15px;
        font-size: 0.9em;
        vertical-align: top;
        a:only-child {
            display: block;
            text-decoration: none;
        }
    }

    .table-separated {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .table-blocked {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
}
