$scroll-positions: (top, bottom);

.panel-container {
    padding: 15px 30px;
    width: 100%;
    color: #000;
    @include flexbox();
    @include flex-direction(column);

    @each $scroll-position in $scroll-positions {
        .panel-#{$scroll-position} {
            width: 100%;
            height: 60px;
            background-image: url(/assets/images/scroll/scroll-#{$scroll-position}-center.png);
            background-size: 1px 100%;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: -20px;
                right: -20px;
                top: 0;
                bottom: 0;
                background-image: url(/assets/images/scroll/scroll-#{$scroll-position}-left.png), url(/assets/images/scroll/scroll-#{$scroll-position}-right.png);
                background-repeat: no-repeat;
                background-size: 21px 100%, 21px 100%;
                background-position: left center, right center;
            }
        }

        &.panel-small .panel-#{$scroll-position} {
            height: 30px;

            &::before {
                left: -10px;
                right: -10px;
                background-size: 11px 100%, 11px 100%;
            }
        }

        &.panel-tiny .panel-#{$scroll-position} {
            height: 15px;

            &::before {
                left: -5px;
                right: -5px;
                background-size: 6px 100%, 6px 100%;
            }
        }
    }

    .panel-bottom::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.04);
    }

    .panel-content {
        background-color: #eccd86;
        position: relative;
        padding: 30px;
        margin: 0 15px;
        @include flex(1 0 0);

        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 12px;
            background-size: 100% 500px;
        }

        &::before {
            right: 100%;
            background-image: url(/assets/images/scroll/scroll-left.png);
        }

        &::after {
            left: 100%;
            background-image: url(/assets/images/scroll/scroll-right.png);
        }
    }

    &.panel-small .panel-content {
        padding: 15px;
        margin: 0 7px;

        &::before, &::after {
            background-size: 100% 250px;
            width: 6px;
        }
    }

    &.panel-tiny .panel-content {
        padding: 5px;
        margin: 0 3px;

        &::before, &::after {
            background-size: 100% 125px;
            width: 3px;
        }
    }

    &.panel-sticky {
        position: sticky;
    }
}
