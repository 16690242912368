@import "variables/arvarva";
@import "mixins/flexbox";
@import "functions/string";
@import "components/text";
@import "components/layout";
@import "components/fonts";
@import "components/scroll";
@import "components/forms";
@import "components/tables";
@import "components/pager";
@import "components/avc";
@import "pages/story";
@import "pages/authors";
@import "pages/guide";

*, ::before, ::after {
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    font-size: inherit;
    font-family: 'Arvo', serif;
    padding: 0;
    margin: 0;
    background-image: url('/assets/images/background.png');
    color: #000;
}

h1, h2, h3, h4, h5, h6, .heading {
    font-family: 'Germania One', serif;
}

h1 {
    text-align: center;
}

a {
    color: #000;
    text-decoration: underline dotted;

    &:hover, &:focus, &:active {
        color: #555;
        text-decoration: underline dotted;
    }
}

hr {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
    .page-container {
        @include flexbox();

        &.page-wrap {
            @include flex-wrap(wrap);
        }

        .page-sidebar {
            min-width: 300px;
            @include flex(0 0 1);

            a {
                display: block;
                text-decoration: none;

                &:hover, &:focus, &:active {
                    text-decoration: none;
                }
            }
        }

        .page-main {
            @include flex(1 0 0);
        }

        .page-min {
            @include flex(0 0 1);
        }

        .page-gap {
            padding: 0 15px;
        }

        .page-stretch {
            @include align-items(stretch);
            @include flexbox();
        }

        .page-stretch-height {
            @include align-items(stretch);
            @include flexbox();
            @include flex-direction(column);
        }
    }
}

.spacing {
    padding-bottom: 10px;
}

.block-gap {
    padding-left: 15px;
    padding-right: 15px;
}

.icon {
    width: 1em;
    display: inline-block;

    svg {
        fill: currentColor;
        vertical-align: middle;
    }

    img {
        max-width: 100%;
    }
}

.title {
    font-family: 'Germania One', serif;
}

a.link-wide {
    display: block;
}

a.link-block {
    padding: 5px 15px;
    text-decoration: none;
}

.bordered {
    padding: 10px 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
