.guide {
    .guide-container {
        @include flexbox();
        @include flex-wrap(wrap);

        .guide-content {
            @include flex(1 0 0);
            @media (min-width: 768px) {
                @include flex(0 1 auto);
                width: 50%;

                @for $i from 1 through 6 {
                    &.guide-content-#{$i} {
                        width: 100% / $i;
                    }
                    @if $i > 1 {
                        @for $j from 2 through $i {
                            &.guide-content-#{$i}-#{$j} {
                                width: (100% * $j) / $i;
                            }
                        }
                    }
                }
            }
            padding: 15px;

            @include align-items(stretch);
            @include flexbox();
            @include flex-direction(column);

            .guide-block {
                display: block;
                border: 1px solid rgba(0, 0, 0, 0.2);
                padding: 10px;

                @include flex(1 0 0);

                &::after {
                    content: '';
                    display: table;
                    clear: both;
                }

                h2 {
                    padding-top: 0;
                    border-top: none;
                }
            }

            a.guide-block {
                text-decoration: none;

                &:hover, &:focus {
                    background-color: rgba(255, 255, 255, 0.04);
                }
            }
        }
    }

    h2 {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 0.8em;
    }
}
