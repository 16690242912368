.story {
    a {
        background-color: rgba(0, 0, 2505, 0.1);

        &:hover, &:focus {
            background-color: rgba(0, 0, 0, 0.15);
        }
    }
}

.chapter-list a {
    white-space: nowrap;
}
