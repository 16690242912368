
@for $size from 4 through 20 {
    .size-#{zerofill(#{$size}, 2)} {
        font-size: $size * 0.1em;
    }
}

.small, small {
    font-size: 0.7em;
}

.smaller {
    font-size: 0.4em;
}

.large {
    font-size: 1.4em;
}

.larger {
    font-size: 1.8em;
}

.text-center {
    text-align: center;
}
