.pager {
    text-align: center;

    a, span {
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: inline-block;
        margin: 5px 2px;
        min-width: 2em;
        padding: 3px;
    }

    span {
        background-color: rgba(0, 0, 0, 0.07);
    }

    a {
        background-color: rgba(0, 0, 0, 0.04);
        text-decoration: none;

        &:hover, &:focus {
            background-color: rgba(0, 0, 0, 0.07);
        }
    }
}
