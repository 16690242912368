.text-center {
    text-align: center;
}

.text-content {
    text-align: justify;
    line-height: 1.8;

    p {
        margin: 1.8em 0;
    }
}

.text-nowrap {
    white-space: nowrap;
}
