body.avc {
    overflow: hidden;

    .avc-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        background-color: rgba(0, 0, 0, 0.5);

        .avc-content {
            max-width: 720px;
            width: 100%;

            a.avc-button {
                display: block;
                padding: 15px;
                text-transform: uppercase;
                text-align: center;
                border: 1px solid currentColor;
                text-decoration: none;
            }
        }
    }
}
